<template>
  <div v-show="!isHidden" :class="CSSClasses" :style="computedStyle">
    <el-form-item
      :prop="name"
      :label="label"
      :class="elFormCssClasses"
      :style="elFormCss"
      :rules="[
        { required: _isRequired, message: $locale.main.message.required_field, trigger: 'blur' }
      ]"
    >
      <span slot="label" :class="labelCssClasses" :style="labelCss">
        {{ label }}
        <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
          <i class="el-icon-question"></i>
        </el-tooltip>
      </span>

      <AddressMultiField
        :level="level"
        :placeholder="placeholder"
        :value="addresses"
        :required-level="requiredLevel"
        :default-address="defaultAddress"
        @input="$emit('input', $event)"
      ></AddressMultiField>
    </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'
import registryMixin from './registry_mixins'
import InputLabel from '@/mixins/inputLabel.js'

import AddressMultiField from '@/core/infrastructure/components/AddressMultiField'

export default {
  name: 'address_multi_field',
  mixins: [mixin, VisibleMixin, registryMixin, InputLabel],
  components: {
    AddressMultiField
  },
  props: {
    registry_properties: {
      type: Array,
      frozen: true
    },
    label: {
      type: String,
      description: 'name'
    },
    placeholder: {
      type: String,
      description: 'placeholder'
    },
    tooltip: {
      type: String,
      description: 'tooltip'
    },
    defaultAddress: {
      type: String,
      description: 'default_address'
    },
    requiredLevel: {
      type: String,
      description: 'required_level',
      editor: 'Select',
      options: {
        options: [
          { id: 'subject', name: 'Регион' },
          { id: 'municipal_area', name: 'Район' },
          { id: 'city', name: 'Город' },
          { id: 'locality', name: 'Населенный пункт' },
          { id: 'planning_structure', name: 'Планировочная структура' },
          { id: 'street', name: 'Улица' },
          { id: 'house', name: 'Дом' },
          { id: 'flat', name: 'Квартира/Помещение' },
          { id: 'description', name: 'Описание' }
        ]
      }
    }
  },
  data () {
    return {
      addresses: []
    }
  },
  async mounted () {
    await this.parseValue()
  },
  methods: {
    async parseValue () {
      if (!this.value) {
        return false
      }

      let parsed = this.value
      try {
        parsed = JSON.parse(this.value)
      } catch (e) {
      }

      let addressesPromises = []
      if (Array.isArray(parsed)) {
        for (const address of parsed) {
          if (typeof parsed === 'object') {
            addressesPromises.push(this.getAddressById(address.id))
          } else {
            addressesPromises.push(this.getAddressById(address))
          }
        }
      } else {
        if (typeof parsed === 'object') {
          addressesPromises.push(this.getAddressById(parsed.id))
        } else {
          addressesPromises.push(this.getAddressById(parsed))
        }
      }

      this.addresses = await Promise.all(addressesPromises)
      this.$emit('input', this.addresses)
    },
    async getAddressById (addressId) {
      if (!addressId) {
        return {}
      }

      let data = await this.$http.get(`${this.$config.api}/fiasaddressservice/addresses?id=${addressId}`)
      return data.data ? Object.assign(data.data[0], {
        name: data.data[0].address,
        address_id: data.data[0].id
      }) : {}
    }
  },
  computed: {
    level () {
      let level = (this.registry_properties.find(item => item.id === 'address_level_id') || {}).value || 'house'
      return level === 'flat' ? 'house' : level
    },
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    }
  },
  watch: {
    value: {
      async handler (newValue) {
        // Обработать ответ от сервера при сохранении
        if (typeof newValue === 'string') {
          // Ответ от БД приходит string, не массивом
          // Обработать ответ из БД для присвоения address_id (id), после добавления новых адресов (без id)
          await this.parseValue()
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
